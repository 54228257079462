import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// MediData
import Home from "./views/Home";
import Shipping from "./views/Step10_Shipping";
import Confirmation from "./views/Step20_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/de/home" replace />} />
                <Route path="*" element={<PageNotFound />} />

                <Route path="/:language/home" element={<Home />} />
                <Route path="/:language/shipping" element={<Shipping />} />
                <Route path="/:language/confirmation" element={<Confirmation />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;