import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import "../css/style.css";

const Step10_Shipping = () => {
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [order, setOrder] = useState({});
    const [promoCode] = useState(sessionStorage.getItem("PromoCode"));
    const [token, setToken] = useState(sessionStorage.getItem("token"));

    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        company: "",
        country: "176",
        email: "",
        confirmemail: "",
        ticketTypeId: ""
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        default:
            languageId = 0;
            break;
    }

    //useEffect(() => {
    //    requestFormSettings();
    //}, []); //only on first page load

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if (promoCode === null || promoCode === "") navigate(`/${language}/home`);

        requestResources();

        if (orderId !== "") {

            async function loadOrder() {
                let order = await requestCompletedOrder();

                setOrder(order);
            }

            loadOrder();

        } else {
            navigate(`/${language}/home`);
        }

    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const addAddressToBasket = () => {
        let addressBody = {
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            countryId: user.country,
            email: user.email, 

        };
        axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then((res) => {
                addTicketToBasket();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const addTicketToBasket = () => {
        let addTicketTypes = [];

        addTicketTypes = [{ ticketTypeId: user.ticketTypeId, quantity: 1, promotionCode: promoCode }];

        axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                sessionStorage.setItem("BookedTicketTypeId", user.ticketTypeId);
                navigate(`/${language}/confirmation`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const requestCompletedOrder = async () => {
        var response = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
        return response.data;
    };

    const onSubmit = () => {
        addAddressToBasket();
    };

    const onOptionsChange = (e) => {
        setUser({ ...user, ["ticketTypeId"]: e.target.value });
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // validating input fields
    let validation =
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.company) &&
        /^\S+@\S+\.\S+$/.test(user.email) &&
        /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
        user.email === user.confirmemail &&
        /^.{1,10}$/.test(user.ticketTypeId);

    return (
        <div>
            <>
                {resources.translation && (
                    <div id="main-container" className="container pt-0 pb-5 wrapper">
                        <Header language={language} />
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <h4 className="fw-bolder">{resources.translation._Woohw}</h4>
                                <h4 className="fw-bolder">{resources.translation.HomeTitle}</h4>
                            </div>
                        </div>

                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <span>{resources.translation.HomeDate}</span><br />
                                <span>{resources.translation.HomeLocation}</span>
                            </div>
                        </div>

                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <span className="fw-bolder">{resources.translation.HomeRegistration}</span>
                            </div>
                        </div>

                        <div className="container wrapper-user-data">
                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label>{resources.translation.HomeLastName}</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        name="lastname"
                                        className="form-control"
                                        value={user.lastname}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label>{resources.translation.HomeFirstName}</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        name="firstname"
                                        className="form-control"
                                        value={user.firstname}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label>{resources.translation.HomeCompany}</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        name="company"
                                        className="form-control"
                                        value={user.company}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label>{resources.translation.HomeEmail}</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        value={user.email}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <label>{resources.translation.HomeEmailConfirmation}</label>
                                </div>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        name="confirmemail"
                                        className="form-control"
                                        value={user.confirmemail}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                            </div>

                            {/* ticket types */}

                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <input
                                        id="ticketType1"
                                        type="radio"
                                        value="705504"
                                        name="rdt-ticket-type"
                                        onChange={(e) => onOptionsChange(e)} />
                                    <label htmlFor="ticketType1" className="lbl-rdt">{resources.translation.HomeTicket1}</label>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        id="ticketType2"
                                        type="radio"
                                        value="705505"
                                        name="rdt-ticket-type"
                                        onChange={(e) => onOptionsChange(e)} />
                                    <label htmlFor="ticketType2" className="lbl-rdt">{resources.translation.HomeTicket2}</label>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <input
                                        id="ticketType3"
                                        type="radio"
                                        value="705506"
                                        name="rdt-ticket-type"
                                        onChange={(e) => onOptionsChange(e)} />
                                    <label htmlFor="ticketType3" className="lbl-rdt">{resources.translation.HomeTicket3}</label>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <span>{resources.translation._Mandatory}</span>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <input
                                        id="ticketType4"
                                        type="radio"
                                        value="705507"
                                        name="rdt-ticket-type"
                                        onChange={(e) => onOptionsChange(e)} />
                                    <label htmlFor="ticketType4" className="lbl-rdt">{resources.translation.HomeCannotParticipate}</label>
                                </div>
                            </div>
                        </div>

                        <div className="row pt-3 mt-5 mb-3">
                            <div className="offset-md-4 col-md-4">
                                <button className="btn form-control btn-custom" onClick={onSubmit} disabled={!validation}>
                                    {resources.translation.HomeConfirm}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="container wrapper">
                    <Footer language={language} />
                </div>
            </>
        </div>
    );
};

export default Step10_Shipping;