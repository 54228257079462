import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import "../css/style.css";

const Step20_Confirmation = () => {
    const [resources, setResources] = useState({});
    const [orderId] = useState(sessionStorage.getItem("OrderId"));
    const [bookedTicketTypeId] = useState(sessionStorage.getItem("BookedTicketTypeId"));
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");
    const [cannotParticipateTicketTypeId, setCannotParticipateTicketTypeId] = useState(0);

    // fetching resources
    const { language } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);
        });

        requestFormSettings()
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            setCannotParticipateTicketTypeId(res.data.cannotParticipateTicketTypeId);
            loadOrder(orderId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
                confirmOrder();
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);

            setOrder(order);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);

            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.error(error.response.data));
    };

    // confirm the order
    const confirmOrder = () => {
        axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`).catch((error) => console.error(error.response.data));
    };

    const confirmationMessage = () => {
        if ((bookedTicketTypeId && (cannotParticipateTicketTypeId > 0)) &&
            (bookedTicketTypeId == cannotParticipateTicketTypeId)) {
            return (
                <div>
                    <div className="row mt-5 mb-5 text-center">
                        <div className="col-md-12">
                            <span dangerouslySetInnerHTML={{ __html: resources.translation.ConfirmationTextCannotParticipate }}></span>
                        </div>
                    </div>
                </div>
            )
        } else if (bookedTicketTypeId && (cannotParticipateTicketTypeId > 0)) {
            return (
                <div>
                    <div className="row mt-5 text-center">
                        <div className="col-md-12">
                            <span dangerouslySetInnerHTML={{ __html: resources.translation.ConfirmationTextParticipate }}></span>
                        </div>
                    </div>

                    <div className="row pt-3 mt-5 mb-3">
                        <div className="offset-md-4 col-md-4">
                            <button className="btn form-control btn-custom" onClick={() => getPdfTicketsOfOrder()}>
                                {resources.translation.ConfirmationDownload}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <>
                {resources.translation && (
                    <div id="main-container" className="container pt-0 pb-5 wrapper">
                        <Header language={language} />
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <h4 className="fw-bolder">{resources.translation._Woohw}</h4>
                                <h4 className="fw-bolder">{resources.translation.HomeTitle}</h4>
                            </div>
                        </div>

                        {confirmationMessage()}
                    </div>
                )}
                <div className="container wrapper">
                    <Footer language={language} />
                </div>
            </>
        </div>
    );
};

export default Step20_Confirmation;
