import axios from "axios";
import React, { useEffect, useState } from "react";

import logo from "../../images/logo.png";

import "../../css/style.css";

const Header = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div className="pt-5">
            <div className="row">
                <div className="offset-md-3 col-md-6 text-center">
                    <img src={logo} className="pe-5 ps-5 img-fluid text-center" alt="logo"></img>
                </div>
            </div>
        </div>
    );
};

export default Header;
