import axios from "axios";
import React, { useEffect, useState } from "react";

import footerImg from "../../images/footer2.png";

const Footer = (props) => {
    const [resources, setResources] = useState({});

    useEffect(() => {
        requestResources();
    }, [props.language]);

    const requestResources = () => {
        axios.get(`form/resources/${props.language}`).then((res) => {
            setResources(res.data);
        });
    };

    return (
        <div>
            <div className="row">
                <div className="col-md-12 p-0">
                    <img src={footerImg} className="img-fluid" alt="footer"></img>
                </div>
                <div className="text-center">
                    {resources.translation && (
                        <small style={{ color: 'grey', fontSize: '10px' }}>ticketing by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Footer;
