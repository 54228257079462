import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import "../css/style.css";

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [promoError, setPromoError] = useState("");
    const [token, setToken] = useState(sessionStorage.getItem("token"));

    const [user, setUser] = useState({
        promoCode: "",
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();
    }, []); //only on first page load

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            setToken(res.data);
            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId);
        });
    };

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }, [orderId]); //everytime orderId is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const startOrder = (posId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            languageId: languageId,
            paymentType: 5
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const applyPromotionCode = () => {
        axios
            .put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${user.promoCode}`)
            .then((res) => {
                sessionStorage.setItem("PromoCode", user.promoCode);
                setPromoError("");
                navigate(`/${language}/shipping`);
            })
            .catch((error) => {
                setPromoError(resources.translation.HomePromocodeError);
            });
    };

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const onSubmit = async () => {
        applyPromotionCode();
    };

    // validating input fields
    let validation =
        /^.{1,70}$/.test(user.promocode);

    return (
        <div>
            <>
            {resources.translation && (
                    <div id="main-container" className="container pt-0 pb-5 wrapper">
                        <Header language={language} />
                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <h4 className="fw-bolder">{resources.translation._Woohw}</h4>
                                <h4 className="fw-bolder">{resources.translation.HomeTitle}</h4>
                            </div>
                        </div>

                        <div className="row mt-5 text-center">
                            <div className="col-md-12">
                                <span>{resources.translation.HomeDate}</span><br />
                                <span>{resources.translation.HomeLocation}</span>
                            </div>
                        </div>
                        
                        <div className="container wrapper-user-data">
                            <div className="row pt-4 mt-4 mb-5">
                                <div className="offset-md-1 col-md-6 mt-2">
                                    <input
                                        type="text"
                                        name="promoCode"
                                        placeholder={resources.translation.HomeEnterCode}
                                        className="promocode-textbox form-control text-center"
                                        value={user.promoCode}
                                        onChange={(e) => onInputChange(e)}
                                        required
                                    />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <button className="btn form-control btn-custom" onClick={onSubmit} disabled={!validation}>
                                        {resources.translation.HomeConfirm}
                                    </button>
                                </div>
                            {promoError && promoError != "" &&
                                <div className="offset-md-1 col-md-10 red">
                                    <p>{promoError}</p>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            <div className="container wrapper">
                <Footer language={language}/>
            </div>
            </>
        </div>
    );
};

export default Home;